import {
  Box,
  Flex,
  Text,
  Separator,
  Switch,
  Button,
  Radio,
} from "@radix-ui/themes";
import React, { useEffect } from "react";
import { styled } from "@stitches/react";
import colors from "./colors";
import upIcon from "../icons/up.svg";
import downIcon from "../icons/bottom.svg";
import rightIcon from "../icons/right.svg";
import leftIcon from "../icons/left.svg";
import dotIcon from "../icons/dot.svg";
import zoomInIcon from "../icons/zoom-in.svg";
import zoomOutIcon from "../icons/zoom-out.svg";
import separatorIcon from "../icons/separator.svg";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import api from "../api/api";
import RtspLive from "./RtspLive";
import { cameraCommands } from "./constants";
import { useAppContext } from "../context/appContext";

const Container = styled(Flex, {
  justifyContent: "flex-start",
  padding: "20px 10px 20px 10px",
});

const Card = styled(Box, {
  borderRadius: 10,
  border: "1px solid #D9D9D9",
  background: "#FCFDFF",
  boxShadow: "0px 2px 6px 0px rgba(183, 181, 188, 0.12)",
  Width: "240px",
});

const Controller = styled(Flex, {
  width: "140px",
  height: "140px",
  borderRadius: "50%",
  border: `4px solid ${colors.base}`,
  background: colors.deepGray,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
});

const InnerCircle = styled(Flex, {
  position: "relative",
  width: "120px",
  height: "120px",
  borderRadius: "50%",
  background: "White",
});

const Arrow = styled(Box, {
  cursor: "pointer",
  position: "absolute",
  transition: "box-shadow 0.2s ease, transform 0.2s ease",
  "&:active": {
    transform: "scale(0.95)", // Slightly shrink on click
  },
  // Variants for different arrow positions
  variants: {
    position: {
      top: {
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)", // Center horizontally
      },
      bottom: {
        bottom: "5px",
        left: "50%",
        transform: "translateX(-50%)", // Center horizontally
      },
      left: {
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)", // Center vertically
      },
      right: {
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)", // Center vertically
      },
    },
  },
});

const MoreButton = styled(Button, {
  height: "15px !important",
  background: `${colors.secondary} !important`,
  borderRadius: "10px",
});

const CameraListContainer = styled(Flex, {
  maxHeight: "300px",
  overflowY: "auto",
  direction: "column",
});

const FlyingEye = () => {
  const { selectedProject } = useAppContext();
  const [cameras, setCameras] = React.useState([]);
  const [selectedCamera, setSelectedCamera] = React.useState(null);
  const [showAllCameras, setShowAllCameras] = React.useState(false);

  const fetchCameras = async () => {
    const response = await api.get(`/camera/project/${selectedProject?.id}`);
    if (response.data.length > 0) {
      setSelectedCamera(response.data[0]);
    }
    let cameras=response.data;
    const email=localStorage.getItem("email");
    const listOfEmails=["gavriel.m@structura.co.il","shahar.ezrachi@gmail.com","yechiel@structura.co.il"]
    debugger;
    if(email && listOfEmails.includes(email)){
      cameras=cameras.filter(cam=>cam.name.toString().includes("PTZ"))
    }
    //set the first camera selected to be true and the rest to be false
    setCameras(
        cameras.map((camera, index) => ({
        ...camera,
        selected: index === 0,
      }))
    );
  };

  useEffect(() => {
    fetchCameras();
  }, [selectedProject?.id]);

  const sendCameraCommand = async (command) => {
    try {
      await api.post(`/camera/execute/${selectedCamera.id}/${command}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCameraSwitch = (camera) => {
    const updatedCameras = cameras.map((c) => {
      if (c.id === camera.id) {
        return { ...c, selected: !c.selected };
      }
      return c;
    });
    setCameras(updatedCameras);
  };

  const handleOnStreamSuccess = (cameraId, data) => {
    const updatedCameras = cameras.map((c) => {
      if (c.id === cameraId) {
        return { ...data, selected: true };
      }
      return c;
    });
    setCameras(updatedCameras);
  };

  const selectedCameras = cameras.filter((camera) => camera.selected);
  const displayType = selectedCameras.length > 1 ? "multiple" : "single";

  return (
    <Container gapX="8px">
      <Box>
        <Card>
          <Flex justify="center" align="center" p="10px" width="240px">
            <Text size="3" color="violet" weight="bold">
              Flying Eye
            </Text>
          </Flex>
        </Card>
        <Card mt="20px">
          <Flex
            p="15px"
            width="240px"
            justify="start"
            align="center"
            gapX="30px"
          >
            <Controller justify="center" align="center">
              <InnerCircle justify="center" align="center">
                <img src={dotIcon} alt="dot image icon" />
                <Arrow
                  position="top"
                  onClick={() => sendCameraCommand(cameraCommands.MOVE_UP)}
                >
                  <img src={upIcon} alt="up controller icon" />
                </Arrow>
                <Arrow
                  position="bottom"
                  onClick={() => sendCameraCommand(cameraCommands.MOVE_DOWN)}
                >
                  <img src={downIcon} alt="down controller icon" />
                </Arrow>
                <Arrow
                  position="right"
                  onClick={() => sendCameraCommand(cameraCommands.MOVE_RIGHT)}
                >
                  <img src={rightIcon} alt="right controller icon" />
                </Arrow>
                <Arrow
                  position="left"
                  onClick={() => sendCameraCommand(cameraCommands.MOVE_LEFT)}
                >
                  <img src={leftIcon} alt="left controller icon" />
                </Arrow>
              </InnerCircle>
            </Controller>
            <Box>
              <Flex direction="column" gapY="10px">
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => sendCameraCommand(cameraCommands.ZOOM_IN)}
                >
                  <img src={zoomOutIcon} alt="zoom out icon" />
                </Box>
                <img src={separatorIcon} alt="separator in icon" />
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => sendCameraCommand(cameraCommands.ZOOM_OUT)}
                >
                  <img src={zoomInIcon} alt="zoom in icon" />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Card>

        <Card mt="20px">
          <Flex
            direction="column"
            style={{
              borderBottom: "1px solid #D9D9D9",
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
            }}
            p="24px 16px"
          >
            <Text size="3" weight="bold">
              Camera selection
            </Text>
            <Text size="1" mt="1">
              Select camera(s) to display
            </Text>
          </Flex>
          <Flex p="24px 16px" direction="column" gapY="16px">
            <CameraListContainer direction="column" gapY="16px">
              {(showAllCameras ? cameras : cameras.slice(0, 5)).map(
                (camera, index) => (
                  <Box key={camera.id}>
                    <Flex align="center">
                      <Text size="2">{camera.name}</Text>
                      <Switch
                        size="1"
                        defaultChecked={index === 0}
                        ml="auto"
                        onCheckedChange={() => handleCameraSwitch(camera)}
                      />
                    </Flex>
                    {index < cameras.length - 1 && <Separator size="4" />}
                  </Box>
                )
              )}
            </CameraListContainer>
            {cameras.length > 5 && (
              <MoreButton onClick={() => setShowAllCameras(!showAllCameras)}>
                <MdOutlineKeyboardDoubleArrowDown
                  color={colors.active}
                  style={{
                    transform: showAllCameras
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </MoreButton>
            )}
          </Flex>
        </Card>

        <Card p="20px" mt="20px">
          <Text size="3" weight="bold">
            Camera Option
          </Text>
          <Flex align="center" mt="25px">
            <Text size="2">Mainstream</Text>
            <Radio name="mainstream" value="mainstream" ml="auto" />
          </Flex>
          <Flex align="center" mt="4px">
            <Text size="2">Substream</Text>
            <Radio name="mainstream" value="mainstream" checked ml="auto" />
          </Flex>
        </Card>
      </Box>
      <Card style={{ flexGrow: 1 }}>
        <Flex
          gap="10px"
          style={{ padding: "20px" }}
          wrap="wrap"
          justify="start"
        >
          {selectedCameras.map((camera) => (
            <RtspLive
              camera={camera}
              onStreamSuccess={handleOnStreamSuccess}
              key={camera.id}
              active={camera.id === selectedCamera.id}
              onActive={(camera) => setSelectedCamera(camera)}
              displayType={displayType}
            />
          ))}
        </Flex>
      </Card>
    </Container>
  );
};

export default FlyingEye;
