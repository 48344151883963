/* global Autodesk */
import axios from "axios";
import Client from "./viewer-auth";
import api from "../../api/api";

const bucketName = "atom-construction-bucket-eu";

let viewer;

function launchViewer(div, urn, hierachy, onFetch) {
  Client.getAccesstoken()
    .then((token) => {
      const options = {
        env: "AutodeskProduction",
        accessToken: token.access_token,
      };

      Autodesk.Viewing.Initializer(options, () => {
        const htmlDiv = document.getElementById(div);
        viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
        const startedCode = viewer.start();
        if (startedCode > 0) {
          console.error("Failed to create a Viewer: WebGL not supported.");
          return;
        }

        const documentId = `urn:${urn}`;
        Autodesk.Viewing.Document.load(
          documentId,
          (viewerDocument) => {
            const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            viewer.loadDocumentNode(viewerDocument, defaultModel);

            // Add event listener for selection change
            viewer.addEventListener(
              Autodesk.Viewing.SELECTION_CHANGED_EVENT,
              (event) => {
                const selection = viewer.getSelection();
                if (selection.length > 0) {
                  const dbId = selection[0];
                  handleLayerClick(dbId, hierachy, onFetch); // Handle clicks
                } else {
                  console.log("No element selected.");
                }
              }
            );
          },
          (error) => console.error("Failed to load document:", error)
        );
      });
    })
    .catch((error) => console.error("Failed to retrieve access token:", error));
}

function handleLayerClick(dbId, hierachy, onFetch) {
  viewer.getProperties(dbId, (props) => {
    const nameProp = props["name"];
    if (nameProp && nameProp.includes("Set")) {
      const parts = nameProp.split("_")[1].split(" ")[0]; // Split by "_" and then by space
      const setId = parseInt(parts, 10);
      if (setId) {
        const s3Url = `clients/${hierachy.client}/${hierachy.project}/${hierachy.floor}/${hierachy.element}/${hierachy.object}/AB Versions/`;
        console.log("url", s3Url);
        fetchJsonFromS3(s3Url, setId, onFetch);
      } else {
        console.log("No valid set ID found in the name property.");
      }
    } else {
      console.log("No name property found for the clicked element.");
    }
  });
}

async function fetchJsonFromS3(url, setId, onFetch) {
  try {
    const response = await api.get("autodesk/json", {
      params: {
        bucketName,
        key: url,
        setId: setId,
      },
    });
    onFetch(response.data);
  } catch (error) {
    console.error("Failed to fetch JSON from S3:", error);
  }
}

export default launchViewer;
