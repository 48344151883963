import React, { useState, useRef, useEffect } from "react";
// import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
// import { Button } from './ui/button';
import {
  FaInfoCircle,
  FaSearchPlus,
  FaSearchMinus,
  FaArrowsAlt,
} from "react-icons/fa";
import { Button, Flex, Text } from "@radix-ui/themes";
import { Card, CardContent, CardHeader, CardTitle } from "./Card";
import { styled } from "@stitches/react";

const TooltipContainer = styled("div", {
  position: "absolute",
  backgroundColor: "white",
  padding: "8px",
  borderRadius: "6px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid $colors$gray300",
  zIndex: 1000,
  pointerEvents: "none", // Prevent tooltip from interfering with mouse events
  transition: "all 0.1s ease-out",
});

const RebarVisualization = ({ data }) => {
  const [showAP, setShowAP] = useState(true);
  const [showAB, setShowAB] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [hoveredRebar, setHoveredRebar] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Zoom and pan states
  const [transform, setTransform] = useState({ x: 0, y: 0, scale: 1 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const svgRef = useRef(null);

  //   // Find the bounds of the visualization
  const bounds = data.rebars.reduce(
    (acc, rebar) => {
      const points = [];

      if (rebar.ap_data) {
        points.push(
          [rebar.ap_data.start_x, rebar.ap_data.start_y],
          [rebar.ap_data.end_x, rebar.ap_data.end_y]
        );
      }

      if (rebar.ab_data) {
        points.push(
          [rebar.ab_data.start_x, rebar.ab_data.start_y],
          [rebar.ab_data.end_x, rebar.ab_data.end_y]
        );
      }

      points.forEach(([x, y]) => {
        if (x && y) {
          acc.minX = Math.min(acc.minX, x);
          acc.maxX = Math.max(acc.maxX, x);
          acc.minY = Math.min(acc.minY, y);
          acc.maxY = Math.max(acc.maxY, y);
        }
      });

      return acc;
    },
    { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
  );

  // Add padding to bounds
  const padding = 5;
  bounds.minX -= padding;
  bounds.maxX += padding;
  bounds.minY -= padding;
  bounds.maxY += padding;

  // First, calculate the basic dimensions
  const width = bounds.maxX - bounds.minX;
  const height = bounds.maxY - bounds.minY;
  const svgWidth = 800;
  const svgHeight = 600;
  const baseScale = Math.min(svgWidth / width, svgHeight / height) * 0.9; // 0.9 to leave some margin

  // Then calculate the scaling transform
  const calculateScalingTransform = () => {
    // Find the first rebar with AP data
    const referenceRebar = data.rebars.find((rebar) => rebar.ap_data);

    if (!referenceRebar) return { mainAngle: 0, perpScale: 1 };

    // Calculate the direction vector of the reference rebar
    const dx = referenceRebar.ap_data.end_x - referenceRebar.ap_data.start_x;
    const dy = referenceRebar.ap_data.end_y - referenceRebar.ap_data.start_y;

    // Calculate the main angle of the rebars
    const mainAngle = Math.atan2(dy, dx);

    return { mainAngle, perpScale: 3 }; // Adjust perpScale to control gap visibility
  };

  const { mainAngle, perpScale } = calculateScalingTransform();

  const transformPoint = (x, y) => {
    // First center the point
    const centerX = (bounds.maxX + bounds.minX) / 2;
    const centerY = (bounds.maxY + bounds.minY) / 2;
    const relX = x - centerX;
    const relY = y - centerY;

    // Rotate to align with rebar direction
    const rotatedX = relX * Math.cos(-mainAngle) - relY * Math.sin(-mainAngle);
    const rotatedY = relX * Math.sin(-mainAngle) + relY * Math.cos(-mainAngle);

    // Scale perpendicular direction
    const scaledX = rotatedX;
    const scaledY = rotatedY * perpScale;

    // Rotate back
    const finalX =
      scaledX * Math.cos(mainAngle) - scaledY * Math.sin(mainAngle);
    const finalY =
      scaledX * Math.sin(mainAngle) + scaledY * Math.cos(mainAngle);

    // Apply base scaling and transform
    return {
      x: finalX * baseScale * transform.scale + svgWidth / 2 + transform.x,
      y: finalY * baseScale * transform.scale + svgHeight / 2 + transform.y,
    };
  };

  // Zoom handlers
  const handleWheel = (e) => {
    e.preventDefault();
    const zoomFactor = e.deltaY > 0 ? 0.9 : 1.1;
    const rect = svgRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    setTransform((prev) => {
      const newScale = prev.scale * zoomFactor;
      // Limit zoom scale
      if (newScale < 0.5 || newScale > 5) return prev;

      // Calculate new position to zoom towards mouse
      const x = mouseX - (mouseX - prev.x) * zoomFactor;
      const y = mouseY - (mouseY - prev.y) * zoomFactor;

      return { x, y, scale: newScale };
    });
  };

  // Pan handlers
  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Left click only
      setIsDragging(true);
      setDragStart({ x: e.clientX - transform.x, y: e.clientY - transform.y });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setTransform((prev) => ({
        ...prev,
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      }));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Reset zoom and pan
  const handleReset = () => {
    setTransform({ x: 0, y: 0, scale: 1 });
  };

  // Add zoom controls
  const handleZoomIn = () => {
    setTransform((prev) => ({
      ...prev,
      scale: Math.min(prev.scale * 1.2, 5),
    }));
  };

  const handleZoomOut = () => {
    setTransform((prev) => ({
      ...prev,
      scale: Math.max(prev.scale * 0.8, 0.5),
    }));
  };

  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      svg.addEventListener("wheel", handleWheel, { passive: false });
      return () => svg.removeEventListener("wheel", handleWheel);
    }
  }, []);

  const renderHoverInfo = () => {
    if (!hoveredRebar) return null;

    let info;
    if (showCompare) {
      const hasAPButNoAB = hoveredRebar.ap_data && !hoveredRebar.ab_data;
      const hasABButNoAP = hoveredRebar.ab_data && !hoveredRebar.ap_data;

      if (hasAPButNoAB) {
        info = "Missing AB Rebar!!!";
      } else if (hasABButNoAP) {
        info = "Extra AB Rebar";
      } else if (hoveredRebar.compared_data) {
        const deviations = [];
        const { gap, diameter, length } = hoveredRebar.compared_data;

        if (gap && gap !== 0 && gap !== "nan") {
          deviations.push(`Gap: ${gap}cm`);
        }
        if (diameter && diameter !== 0 && diameter !== "nan") {
          deviations.push(`Diameter: ${diameter}mm`);
        }
        if (length && length !== 0 && length !== "nan") {
          deviations.push(`Length: ${length}cm`);
        }

        if (deviations.length > 0) {
          info = `${deviations.join(", ")}`;
        }
      }
    } else {
      // Show parameters based on which line type is being hovered
      if (hoveredType === "ap") {
        info = `AP Parameters - Diameter: ${data.ap_parameters.diameter}mm, Length: ${data.ap_parameters.length}cm, Gap: ${data.ap_parameters.gap}cm`;
      } else if (hoveredType === "ab" && hoveredRebar.ab_data) {
        info = `AB Parameters - Diameter: ${hoveredRebar.ab_data.diameter}mm, Length: ${hoveredRebar.ab_data.length}cm, Gap: ${hoveredRebar.ab_data.gap}cm`;
      }
    }

    if (!info) return null;

    // Calculate tooltip position
    // Add offset to prevent tooltip from appearing directly under cursor
    const offsetX = 100;
    const offsetY = 100;

    // Get tooltip dimensions (approximate)
    const tooltipWidth = 200; // approximate width
    const tooltipHeight = 40; // approximate height

    // Adjust position if tooltip would go off screen
    let xPos = mousePosition.x - offsetX;
    let yPos = mousePosition.y + offsetY;

    // if (xPos + tooltipWidth > svgWidth) {
    //   xPos = mousePosition.x - tooltipWidth - offsetX;
    // }

    // if (yPos + tooltipHeight > svgHeight) {
    //   yPos = mousePosition.y - tooltipHeight - offsetY;
    // }

    return (
      <TooltipContainer
        style={{
          left: `${xPos}px`,
          top: `${yPos}px`,
        }}
      >
        <Flex justify="center" align="center" gapX="2px">
          <FaInfoCircle style={{ width: "12px", height: "12px" }} />
          <Text size="1">{info}</Text>
        </Flex>
      </TooltipContainer>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Rebar Visualization</CardTitle>
        <Flex gapX="4px">
          <Button
            variant={showAP ? "default" : "outline"}
            onClick={() => setShowAP(!showAP)}
          >
            Show As Planned
          </Button>
          <Button
            variant={showAB ? "default" : "outline"}
            onClick={() => setShowAB(!showAB)}
          >
            Show As Built
          </Button>
          <Button
            variant={showCompare ? "default" : "outline"}
            onClick={() => setShowCompare(!showCompare)}
          >
            Show Compare
          </Button>
        </Flex>
        <Flex gapX="5px" mt="4px">
          <Button
            variant="outline"
            size="sm"
            onClick={handleZoomIn}
            className="flex items-center gap-1"
          >
            <FaSearchPlus />
            Zoom In
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleZoomOut}
            className="flex items-center gap-1"
          >
            <FaSearchMinus />
            Zoom Out
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleReset}
            className="flex items-center gap-1"
          >
            <FaArrowsAlt />
            Reset View
          </Button>
        </Flex>
      </CardHeader>
      <CardContent className="relative">
        <svg
          ref={svgRef}
          width={svgWidth}
          height={svgHeight}
          className="border rounded-lg bg-white cursor-grab active:cursor-grabbing"
          onMouseDown={handleMouseDown}
          onMouseMove={(e) => {
            handleMouseMove(e);
            // Get mouse position relative to SVG container
            const rect = e.currentTarget.getBoundingClientRect();
            setMousePosition({
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            });
          }}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <g
            transform={`translate(${transform.x},${transform.y}) scale(${transform.scale})`}
          >
            {data.rebars.map((rebar, index) => {
              const lines = [];

              if ((showAB || showCompare) && rebar.ab_data) {
                const start = transformPoint(
                  rebar.ab_data.start_x,
                  rebar.ab_data.start_y
                );
                const end = transformPoint(
                  rebar.ab_data.end_x,
                  rebar.ab_data.end_y
                );

                let color = "green"; // default color
                if (showCompare) {
                  if (!rebar.ap_data) {
                    // Case 5: Only AB data exists
                    color = "blue";
                  } else if (rebar.compared_data) {
                    const hasNegative = Object.values(rebar.compared_data).some(
                      (val) => typeof val === "number" && val < 0
                    );
                    const hasPositive = Object.values(rebar.compared_data).some(
                      (val) => typeof val === "number" && val > 0
                    );
                    const allZero = Object.values(rebar.compared_data).every(
                      (val) => val === 0 || val === "nan"
                    );

                    if (hasNegative) {
                      // Case 3: At least one negative parameter
                      color = "red";
                    } else if (hasPositive && !hasNegative) {
                      // Case 2: Only positive parameters
                      color = "blue";
                    } else if (allZero) {
                      // Case 1: All parameters are 0
                      color = "green";
                    }
                  }
                }

                lines.push(
                  <line
                    key={`ab-${index}`}
                    x1={start.x}
                    y1={start.y}
                    x2={end.x}
                    y2={end.y}
                    stroke={color}
                    strokeWidth={4 / transform.scale}
                    strokeOpacity="0.7"
                    className="transition-all duration-200 hover:stroke-width-[16] hover:stroke-opacity-100 hover:filter hover:drop-shadow-[0_0_3px_rgba(0,0,0,0.5)]"
                    style={{
                      filter:
                        hoveredRebar === rebar
                          ? "drop-shadow(0 0 3px rgba(0,0,0,0.5))"
                          : "none",
                      strokeWidth:
                        hoveredRebar === rebar
                          ? `${16 / transform.scale}px`
                          : `${4 / transform.scale}px`,
                    }}
                    onMouseEnter={() => {
                      setHoveredRebar(rebar);
                      setHoveredType("ab");
                    }}
                    onMouseLeave={() => {
                      setHoveredRebar(null);
                      setHoveredType(null);
                    }}
                  />
                );
              }

              // For AP-only rebars (Case 4)
              if (showAP && rebar.ap_data) {
                const start = transformPoint(
                  rebar.ap_data.start_x,
                  rebar.ap_data.start_y
                );
                const end = transformPoint(
                  rebar.ap_data.end_x,
                  rebar.ap_data.end_y
                );

                let color = "gray";
                if (showCompare && !rebar.ab_data) {
                  // Case 4: Only AP data exists
                  color = "red";
                }

                lines.push(
                  <line
                    key={`ap-${index}`}
                    x1={start.x}
                    y1={start.y}
                    x2={end.x}
                    y2={end.y}
                    stroke={color}
                    strokeWidth={4 / transform.scale}
                    strokeOpacity="0.7"
                    className="transition-all duration-200 hover:stroke-width-[16] hover:stroke-opacity-100 hover:filter hover:drop-shadow-[0_0_3px_rgba(0,0,0,0.5)]"
                    style={{
                      filter:
                        hoveredRebar === rebar
                          ? "drop-shadow(0 0 3px rgba(0,0,0,0.5))"
                          : "none",
                      strokeWidth:
                        hoveredRebar === rebar
                          ? `${16 / transform.scale}px`
                          : `${4 / transform.scale}px`,
                    }}
                    onMouseEnter={() => {
                      setHoveredRebar(rebar);
                      setHoveredType("ap");
                    }}
                    onMouseLeave={() => {
                      setHoveredRebar(null);
                      setHoveredType(null);
                    }}
                  />
                );
              }

              return lines;
            })}
          </g>
        </svg>
        {renderHoverInfo()}
      </CardContent>
    </Card>
  );
};

export default RebarVisualization;
