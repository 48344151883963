import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@stitches/react";
import { useAppContext } from "../context/appContext";
import { Flex, Text } from "@radix-ui/themes";

const Container = styled("div", {
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
});

const Iframe = styled("iframe", {
  width: "100%",
  height: "100vh",
  border: "none",
});

const EyalSafety = () => {
  const { selectedProject } = useAppContext();
  const [token, setToken] = useState(null);
  const [navigation, setNavigation] = useState("dashboard");

  // selectedProject.siteId = "9d015a5e-03c7-4078-995f-ef8c6df33034";

  useEffect(() => {
    const fetchToken = async (siteId) => {
      if (token) return;

      const body = {
        site_ids: [siteId],
        company_id: process.env.REACT_APP_COMPANY_ID,
        first_name: process.env.REACT_APP_FIRST_NAME,
        last_name: process.env.REACT_APP_LAST_NAME,
        email: process.env.REACT_APP_EMAIL,
        username: process.env.REACT_APP_USERNAME,
        group_name: process.env.REACT_APP_GROUP_NAME,
      };

      const tokenEndpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
      const bearerToken = process.env.REACT_APP_BEARER_TOKEN;

      try {
        const response = await axios.post(tokenEndpoint, body, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });

        if (response.data && response.data.token) {
          setToken(response.data.token);
        } else {
          console.error("Token not found in response:", response.data);
        }
      } catch (error) {
        console.error("Failed to fetch token:", error);
      }
    };

    selectedProject?.siteId && fetchToken(selectedProject?.siteId);
  }, [selectedProject, token]);

  if (!selectedProject?.siteId) {
    return (
        <Container style={{ height: "100vh" }}>
          <Flex direction="column" align="center" justify="center">
            <Text size="4" weight="bold" color="violet">
              No site id present
            </Text>
            <Text size="2" mt="2" color="violet">
              Can't preview eyal safety page because there is no available site id{" "}
            </Text>
          </Flex>
        </Container>
    );
  }

  const iframeBaseUrl = process.env.REACT_APP_IFRAME_BASE_URL;
  const iframeSrc = token
      ? `${iframeBaseUrl}${token}&minimal=true&path=/alerts/${navigation}`
      : null;

  function setReRender(choose) {
    setToken(null);
    setNavigation(choose);

  }

  return (
      <div>
        <div>
          <button
              onClick={() => setReRender("dashboard")}
              style={{
                margin: "0 5px",
                padding: "10px 15px",
                background: "#6200ea",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
          >
            Dashboard
          </button>
          <button
              onClick={() => setReRender("summary")}
              style={{
                margin: "0 5px",
                padding: "10px 15px",
                background: "#03dac6",
                color: "#000",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
          >
            Summary
          </button>
        </div>
        <Container>
          {iframeSrc ? (
              <Iframe
                  src={iframeSrc}
                  style={{ width: "100vw", height: "100vh", border: "none" }}
                  title="Dynamic Iframe"
              />
          ) : (
              <p>Loading...</p>
          )}
        </Container>
      </div>
  );
};

export default EyalSafety;
