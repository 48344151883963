import React from "react";
import { styled } from "@stitches/react";

const Card = styled("div", {
  borderRadius: "$lg",
  border: "1px solid $slate200",
  backgroundColor: "$white",
  color: "$slate950",
  boxShadow: "$sm",
  variants: {
    variant: {
      default: {},
      outlined: {
        backgroundColor: "transparent",
        border: "1px solid $slate300",
      },
    },
  },
});

const CardHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.375rem",
  padding: "$6",
});

const CardTitle = styled("h3", {
  fontSize: "$2xl",
  fontWeight: "semibold",
  lineHeight: "1.2",
  letterSpacing: "-0.02em",
});

const CardContent = styled("div", {
  padding: "$6",
  paddingTop: "0",
});

export { Card, CardHeader, CardTitle, CardContent };
