import { Box, Button, Flex, Select, Text, TextField } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import colors from "./colors";
import FilterIcon from "../icons/filter.svg";
import ReportIcon from "../icons/report.svg";
import NoReportIcon from "../icons/no-report.svg";
import NoReportIconSmall from "../icons/no-report-small.svg";
import { FaTimes } from "react-icons/fa";
import api from "../api/api";
import {
  extractFileNameFromS3Url,
  getFileNameFromPath,
  getOriginalFilename,
} from "../utils";
import { useAppContext } from "../context/appContext";
import FilterSelect from "./FilterSelect";
import MultiDateSelector from "./MultipleDateSelect";

const Container = styled(Box, {
  background: colors.background,
  padding: "25px",
});

const PDFContainer = styled(Flex, {
  padding: "60px 10px 60px 10px",
  flexGrow: 1,
  borderRadius: "5px",
  background: "#FFF",
  boxShadow: "0px 4px 10px 0px #D9D9D9",
});

const ControlContainer = styled(Box, {
  padding: "25px",
  width: "370px",
  borderRadius: "10px",
  background: "#F5F6FA",
  boxShadow: "0px 4px 6px 0px #D9D9D9",
});

const ReportItemContainer = styled(Box, {
  height: "50vh",
  overflowY: "scroll",
});

const FilterItem = styled(Flex, {
  columnGap: "15px",
});

const RawButton = styled(Button, {
  backgroundColor: "transparent !important",
  color: "inherit !important",
  cursor: "pointer",
});

const NoReportContainer = styled(Flex, {
  padding: "10px",
  borderRadius: "5px",
  background: "#fff",
  boxShadow: "0px 4px 6px 0px rgba(183, 181, 188, 0.12)",
  flexDirection: "column",
});

const StyledReportItem = styled(Flex, {
  background: "#fff",
  borderRadius: "5px",
  padding: "10px",
  color: colors.active,
  marginTop: "10px",
  variants: {
    active: {
      true: {
        backgroundColor: colors.active,
        color: "#fff",
      },
      false: {
        backgroundColor: "#fff",
        color: colors.active,
      },
    },
  },
});

const pdfFileUrl = "https://pdfobject.com/pdf/sample.pdf";

const ReportItem = ({ report, onView, active }) => {
  const name = getFileNameFromPath(report.url ?? "");

  const viewReport = async () => {
    try {
      const response = await api.get(`/report/${report.id}/presigned`);
      onView(report, response.data.downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <StyledReportItem justify="between" align="center" active={active}>
      <Text size="2">{name}</Text>
      <RawButton onClick={() => viewReport()}>
        {active ? "Viewing" : "Open"}
      </RawButton>
    </StyledReportItem>
  );
};

const SightInsights = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { selectedProject } = useAppContext();
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reportFilers, setReportFilters] = useState({
    floors: [],
    elements: [],
    objects: [],
  });
  const [filters, setFilters] = useState({
    date: [],
    floor: [],
    element: [],
    object: [],
  });

  const getFilters = async () => {
    try {
      const response = await api.get("/report/filters");
      setReportFilters(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getReports = async (page, params) => {
    try {
      const response = await api.get("/report", {
        params: { page, limit: 7, project: selectedProject?.name, ...params },
      });
      const reports = response.data.reports;
      //if reports, set selected report to the first report, but we need to fetch the presigned url
      if (reports.length > 0) {
        const response = await api.get(`/report/${reports[0].id}/presigned`);
        setSelectedReport({
          ...reports[0],
          url: response.data.downloadUrl,
        });
      }
      setReports(reports);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  const onView = (report, url) => {
    setSelectedReport({ ...report, url });
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      getReports(currentPage + 1, filters);
    }
  };

  useEffect(() => {
    getReports(currentPage, filters);
  }, [filters]);

  useEffect(() => {
    setSelectedReport(null);
    getReports(1, {});
    getFilters();
  }, [selectedProject?.id]);

  const hasReports = reports.length > 0;
  const appliedFilters = Object.values(filters).some(
    (filter) => filter.length > 0
  );

  const uniqueReportsByName = Array.from(
    new Map(
      reports.map((report) => [
        getFileNameFromPath(report.url),
        { ...report, name: getFileNameFromPath(report.url) },
      ])
    ).values()
  );

  const canLoadMore = currentPage < totalPages;

  return (
    <Container>
      <Text color="violet" size="4">
        Site Insights
      </Text>
      <Flex mt="15px" gapX="25px" height="100vh">
        {selectedReport ? (
          <PDFContainer direction="column" align="center" justify="center">
            <Text
              color="violet"
              size="3"
              weight="bold"
              align="center"
              mb="10px"
            >
              {getOriginalFilename(selectedReport?.url ?? "")}
            </Text>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={selectedReport?.url ?? pdfFileUrl}
                plugins={[zoomPluginInstance]}
              />
            </Worker>
            <Flex gapX="10px" mt="10px">
              <ZoomOutButton />
              <ZoomInButton />
            </Flex>
          </PDFContainer>
        ) : (
          <PDFContainer direction="column" align="center" justify="center">
            <img src={NoReportIcon} alt="no report icon" />
            <Text size="3" color="violet" weight="bold" mt="30px">
              No report to view
            </Text>
            <Text size="2" color="violet" mt="10px">
              Please verify selection or refresh page
            </Text>
          </PDFContainer>
        )}

        <ControlContainer>
          <Flex gapX="25px" align="center">
            <img src={FilterIcon} alt="filter icon" />
            <Text size="3" weight="bold" color="violet">
              Apply filters
            </Text>
          </Flex>
          <Box mt="15px">
            <FilterItem>
              <Flex direction="column" style={{ width: "160px" }}>
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Date
                </Text>
                <MultiDateSelector
                  selectedDates={filters.date}
                  onSelect={(dates) => setFilters({ ...filters, date: dates })}
                />
              </Flex>
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Floor
                </Text>
                <FilterSelect
                  selectedItems={filters.floor}
                  options={reportFilers.floors}
                  onSelect={(floors) =>
                    setFilters({ ...filters, floor: floors })
                  }
                />
              </Flex>
            </FilterItem>

            <FilterItem mt="15px">
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Element
                </Text>
                <FilterSelect
                  selectedItems={filters.element}
                  options={reportFilers.elements}
                  onSelect={(elements) =>
                    setFilters({ ...filters, element: elements })
                  }
                />
              </Flex>
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Object
                </Text>
                <FilterSelect
                  selectedItems={filters.object}
                  options={reportFilers.objects}
                  onSelect={(objects) =>
                    setFilters({ ...filters, object: objects })
                  }
                />
              </Flex>
            </FilterItem>

            {appliedFilters && (
              <Button
                size="1"
                mt="10px"
                variant="soft"
                onClick={() =>
                  setFilters({ date: [], floor: [], element: [], object: [] })
                }
              >
                reset <FaTimes />
              </Button>
            )}
          </Box>

          <Box mt="50px">
            <Flex gapX="15px" align="center" mb="20px">
              <img src={ReportIcon} alt="report icon" />
              <Text size="3" weight="bold" color="violet">
                Reports found
              </Text>
            </Flex>
            <ReportItemContainer>
              {hasReports ? (
                uniqueReportsByName.map((report) => (
                  <ReportItem
                    key={report?.id}
                    report={report}
                    onView={onView}
                    active={selectedReport?.id === report?.id}
                  />
                ))
              ) : (
                <NoReportContainer justify="center" align="center">
                  <Box style={{ width: "25px", height: "25px" }}>
                    <img src={NoReportIconSmall} alt="no report icon" />
                  </Box>
                  <Text size="3" color="violet" weight="bold" mt="20px">
                    No report to view
                  </Text>
                  <Text size="2" color="violet" mt="10px">
                    Please verify selection or refresh page
                  </Text>
                </NoReportContainer>
              )}
            </ReportItemContainer>
            <Flex justify="center" align="center" mt="20px">
              {canLoadMore && (
                <Button variant="soft" size="3" onClick={handleLoadMore}>
                  See more
                </Button>
              )}
            </Flex>
          </Box>
        </ControlContainer>
      </Flex>
    </Container>
  );
};

export default SightInsights;
