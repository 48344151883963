import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import FlyingEye from "./components/FlyingEye";
import ForgetPassword from "./components/ForgetPassword";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/ResetPassword";
import Signup from "./components/Signup";
import SightInsights from "./components/SiteInsights";
import Settings from "./components/Settings";
import { AppProvider } from "./context/appContext";
import EyalSafety from "./components/EyalSafety";
import DWGViewer from "./components/DWGViewer/DwgPoc";
import ViewerComponent from "./components/DWGViewer/DwgPoc";
import Viewer from "./components/DWGViewer/viewer";

// const isLoggedIn = () => {
//   return !!localStorage.getItem("accessToken");
// };

const App = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLoggedIn()) {
  //     // Redirect to dashboard if user is logged in
  //     navigate("/dashboard");
  //   }
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {/* Protect dashboard route */}
      <Route element={<PrivateRoute />}>
        <Route
          path="dashboard/*"
          element={
            <AppProvider>
              <Dashboard />
            </AppProvider>
          }
        >
          {/* Nested routes under dashboard */}
          <Route path="camera" element={<FlyingEye />} />
          <Route path="insights" element={<SightInsights />} />
          <Route path="settings" element={<Settings />} />
          <Route path="eyal-safety" element={<EyalSafety />} />
          <Route path="progress-tracking" element={<Viewer />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
